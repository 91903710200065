<template>
  <b-overlay
    :show="isLoading"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card no-body>
      <b-card-title class="px-2 pt-2">
        รายงานการรับยอดเสีย
      </b-card-title>

      <b-card-body>
        <b-row>
          <b-col
            md="3"
            lg="2"
          >
            <b-form-group label="เลือกวันที่เริ่มต้น (ตามวันที่รับยอดเสีย)">
              <flat-pickr
                v-model="dateStart"
                placeholder="เลือกวันที่"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            lg="2"
          >
            <b-form-group label="เลือกวันที่สิ้นสุด (ตามวันที่รับยอดเสีย)">
              <flat-pickr
                v-model="dateEnd"
                placeholder="เลือกวันที่"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            lg="4"
          >
            <b-form-group label="คำค้นหา">
              <b-form-input
                v-model="term"
                placeholder="ยูส หรือ เบอร์โทร"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            lg="2"
            class="d-flex justify-content-center align-items-center mt-1"
          >
            <b-button
              block
              variant="primary"
              @click="getData()"
            >
              ค้นหา
            </b-button>
          </b-col>
          <b-col
            md="3"
            lg="2"
            class="d-flex justify-content-center align-items-center mt-1"
          >
            <b-button
              v-b-modal.modal-cashback-history
              block
              variant="success"
            >
              EXPORT
            </b-button>
          </b-col>
        </b-row>

      </b-card-body>

      <div class="row px-2">
        <div class="col-12 col-md-6">
          <b-alert
            small
            show
            variant="primary"
            class="text-center"
          >
            <div>
              <h3 class="alert-heading">
                จำนวนคนที่รับ
                <div>{{ dateStart }} ถึง {{ dateEnd }}</div>
              </h3>
            </div>
            <div class="py-2 h2 text-primary">
              {{ totalRows }}
            </div>
          </b-alert>
        </div>
        <div class="col-12 col-md-6">
          <b-alert
            small
            show
            variant="primary"
            class="text-center"
          >
            <div>
              <h3 class="alert-heading">
                ยอดรวม
                <div>{{ dateStart }} ถึง {{ dateEnd }}</div>
              </h3>
            </div>
            <div class="py-2 h2 text-primary">
              {{ summary.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }) }} ฿
            </div>
          </b-alert>
        </div>
      </div>

      <b-card-body class="p-0 m-0">
        <b-table
          striped
          hover
          small
          responsive
          show-empty
          class="mt-2 position-relative items-center"
          :per-page="perPage"
          :items="items"
          :fields="fields"
        >

          <template #empty="scope">
            <div class="p-2 text-center">
              <img
                src="/box.png"
                alt="empty"
                height="40px"
              > {{ scope.emptyFiltered ? 'No matching records found' :
                'ไม่พบข้อมูล' }}
            </div>
          </template>
          <template #cell(created_at)="{ item }">
            {{ item.created_at | dateFormat }}
          </template>
          <template #cell(amount)="{ item }">
            {{ item.amount.toLocaleString("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }) }}
          </template>
          <template #cell(from_wl)="{ item }">
            {{ Number(item.from_wl).toLocaleString("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            }) }}
          </template>
        </b-table>
        <div class="card-body d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <b-form-group
            label="Per Page"
            label-cols="8"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
              @change="getData()"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="getData()"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-cashback-history"
      title="ระบุรหัสผ่าน"
      @ok="checkExportPassword"
    >
      <b-form-input v-model="exportPassword" />
    </b-modal>
  </b-overlay>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'
import {
  BAlert, BBadge, BPagination, BFormSelect, BTable, BPaginate, BIconController, BButton, BFormInput, BRow, BCol, BCardBody, BCard, BCardTitle, BFormGroup, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BAlert,
    BBadge,
    BPagination,
    BFormSelect,
    BTable,
    BPaginate,
    BIconController,
    BButton,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    BCard,
    BCardTitle,
    BFormGroup,
    BFormCheckbox,
    BOverlay,

    flatPickr,
  },
  filters: {
    dateFormat(val) {
      return moment(val).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm')
    },
  },
  data: () => ({
    perPage: 50,
    pageOptions: [50, 100, 200],
    totalRows: 50,
    page: 1,
    items: [],
    type: 'all',
    rangeDate: [
      moment().tz('Asia/Bangkok').clone().subtract(3, 'days')
        .format('YYYY-MM-DD'),
      moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    ],
    fields: [
      { key: 'username', label: 'ยูส' },
      { key: 'tel', label: 'เบอร์' },
      { key: 'invited_by', label: 'ผู้แนะนำ' },
      { key: 'from_wl', label: 'W/L' },
      { key: 'cashback_percent', label: '% ที่ได้รับ' },
      { key: 'amount', label: 'จำนวนเงิน' },
      { key: 'created_at', label: 'วันที่รับยอดเสีย' },
    ],
    isLoading: false,
    dateStart: moment().tz('Asia/Bangkok').clone().subtract(1, 'days')
      .format('YYYY-MM-DD'),
    dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
    search_from: null,
    term: '',
    total_receive: 0,
    summary: 0,
  }),
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      // const startDate = Array.isArray(this.rangeDate) ? this.rangeDate[0] : this.rangeDate.split(' ')[0]
      // const endDate = Array.isArray(this.rangeDate) ? this.rangeDate[1] : this.rangeDate.split(' ')[2]
      this.isLoading = true
      try {
        const { data } = await this.$http.get('/cashback/history', {
          params: {
            page: this.page,
            perPage: this.perPage,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            term: this.term,
          },
        })

        this.totalRows = data.pagination.total
        this.items = data.data
        this.summary = data.summary
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async checkExportPassword() {
      const obj = {
        password: this.exportPassword,
      }
      this.$http
        .post('/export/checkpass', obj)
        .then(response => {
          if (response.data.status === true) {
            this.exportData()
          } else {
            this.$swal({
              icon: 'error',
              title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
              text: 'รหัสผ่านไม่ถูกต้อง',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          }
        })
        .catch(error => console.log(error))
    },
    async exportData() {
      // const startDate = Array.isArray(this.rangeDate) ? this.rangeDate[0] : this.rangeDate.split(' ')[0]
      // const endDate = Array.isArray(this.rangeDate) ? this.rangeDate[1] : this.rangeDate.split(' ')[2]
      try {
        const { data } = await this.$http.get('/cashback/export_history', {
          responseType: 'blob',
          params: {
            page: this.page,
            perPage: this.perPage,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            term: this.term,
          },
        })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(
          new Blob([data]),
        )

        link.setAttribute('download', 'Report.xlsx')
        document.body.appendChild(link)
        link.click()
      } catch (error) {

      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
